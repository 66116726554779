<template>
  <div class="image-with-mask">
    <div v-if="isMask" class="image-with-mask__mask" :class="maskPosition" />

    <div class="image-with-mask__wrapper content-container-template">
      <template v-for="image in flatGallery" :key="image.id">
        <component
          :is="isLinkValid(image.properties.linkData) ? 't3-link' : 'div'"
          :to="image.properties.linkData"
          :class="imageBorder"
          class="image-with-mask__image-wrapper"
        >
          <UiImg :image="image" class="image-with-mask__image" use-image-size />
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeGalleryProps } from '~/types'
import { UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<UiCeGalleryProps>()

const flatGallery = useFlatGallery(props)

const isMask = computed(() => props.appearance?.maskPosition !== 'none')

const maskPosition = computed(() =>
  isMask.value ? `image-with-mask__mask--${props.appearance?.maskPosition}` : ''
)

const imageBorder = computed(() =>
  props.gallery?.border?.enabled ? 'image-with-mask__image--border' : ''
)
</script>

<style lang="scss">
.image-with-mask {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  position: relative;
  isolation: isolate;

  &__mask {
    background-color: color(white);
    position: absolute;
    width: 100%;
    z-index: z-index(below);

    &--top {
      top: 0;
      bottom: 50%;
    }

    &--down {
      top: 50%;
      bottom: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(48px);
    width: 100%;
  }

  &__image {
    &-wrapper {
      width: 100%;
      height: auto;
    }

    width: 100%;
    height: 100%;

    &--border {
      background-color: color(white);
    }

    &--border .ui-img {
      box-shadow:
        0 2px 20px 0 hsl(0 0% 0% / 4%),
        0 8px 16px hsl(0 0% 0% / 5%);
      border-radius: em(4px);
      border: 10px solid color(white);
    }
  }
}
</style>
